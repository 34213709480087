<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span>{{ lang("t_scheduler") }}</span>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                variant="primary"
                @click="
                  isEdit = false;
                  modal_record_details = true;
                  clear_data();
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
        :filter="searchQuery"
      >
        <template #cell(profiles)="data">
          {{ getProfileDisplayNames(data.item.profiles) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(break_time)="data">
          {{ parseInt(data.item.break_time / 60) }}
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ records.length }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="setScheduler"
    >
      <b-container>
        <b-row class="border-bottom mb-1 mt-1" align-h="center">
          <h4>
            <b-badge
              class="badge-glow mb-1"
              :variant="isCronInvalid() ? 'primary' : 'danger'"
            >
              {{ scheduler_info.readable_string }}
            </b-badge>
          </h4>
        </b-row>
      </b-container>
      <b-row>
        <b-col>
          <b-form-group :label="lang('t_displayName')">
            <b-form-input
              v-model="scheduler_info.display_name"
              :placeholder="lang('t_displayName')"
              trim
            /> </b-form-group
        ></b-col>
        <b-col>
          <b-form-group :label="lang('t_schedulerCategory')">
            <v-select
              :placeholder="lang('t_schedulerCategory')"
              v-model="scheduler_info.selected_scheduler_category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="scheduler_categories"
            /> </b-form-group
        ></b-col>
      </b-row>
      <div v-if="scheduler_info.selected_scheduler_category == 'report'">
        <b-row>
          <b-col>
            <b-form-group :label="lang('t_reportCategory')">
              <v-select
                :placeholder="lang('t_reportCategory')"
                v-model="report_category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="categories"
                @input="clear_report"
              /> </b-form-group
          ></b-col>
          <b-col>
            <b-form-group :label="lang('t_report')">
              <v-select
                :placeholder="lang('t_report')"
                v-model="scheduler_info.selected_report"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :options="
                  report_list.filter((e) => e.category == report_category)
                "
              /> </b-form-group
          ></b-col>

          <b-col>
            <b-form-group :label="lang('t_lastXDays')">
              <b-form-input
                v-model="scheduler_info.filter_values.lastXDays"
                :placeholder="lang('t_lastXDays')"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            :key="i"
            v-for="(item, i) in scheduler_info.selected_report.filters"
          >
            <b-form-group v-if="item.internal_name == 'Project'">
              <v-select
                v-model="scheduler_info.filter_values.selectedProjects"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="projects"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'Queue'">
              <v-select
                v-model="scheduler_info.filter_values.selectedQueues"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="queues"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'Campaign'">
              <v-select
                v-model="scheduler_info.filter_values.selectedCampaigns"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="campaigns"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'IVR'">
              <v-select
                v-model="scheduler_info.filter_values.selectedIvr"
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="ivr_list"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'Agent'">
              <v-select
                v-model="scheduler_info.filter_values.selectedAgents"
                multiple
                :placeholder="lang(item.display_name)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="(item) => item.username"
                :options="agents"
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'RemoteNumber'">
              <b-form-input
                v-model="scheduler_info.filter_values.selectedNumber"
                :placeholder="lang(item.display_name)"
                @update="
                  () => {
                    scheduler_info.filter_values.selectedNumber =
                      scheduler_info.filter_values.selectedNumber
                        .split(' ')
                        .join('');
                  }
                "
                @input="
                  () => {
                    scheduler_info.filter_values.selectedNumber =
                      scheduler_info.filter_values.selectedNumber
                        .split(' ')
                        .join('');
                  }
                "
              />
            </b-form-group>
            <b-form-group v-if="item.internal_name == 'title'">
              <b-form-input
                v-model="scheduler_info.filter_values.title"
                :placeholder="lang(item.display_name)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              v-if="
                scheduler_info.selected_report.internal_name == 'CallDetails'
              "
            >
              <v-select
                v-model="scheduler_info.filter_values.selectedCustomerColumns"
                multiple
                :placeholder="'Ekstra Müşteri Bilgisi Ekle...'"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :reduce="(item) => item"
                :options="project_columns"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-button v-b-modal.modal-crons block variant="outline-primary mb-1">
        {{ lang("t_seeExampleTimePatterns") }}
      </b-button>
      <div>
        <b-row>
          <b-col v-for="(item, i) in Object.keys(cron_info)" :key="i">
            <b-form-group :label="lang(`t_${item}`)">
              <b-form-input
                @input="set_cron"
                v-model="cron_info[item]"
                :placeholder="lang(`t_${item}`)"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col>
            <b-form-group :label="lang(`t_communicationType`)">
              <v-select
                :placeholder="lang('t_communicationType')"
                v-model="scheduler_info.communication_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="communication_types"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="lang(`t_communicationTitle`)">
              <b-form-input
                v-model="scheduler_info.communication_title"
                :placeholder="lang(`t_communicationTitle`)"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="lang(`t_description`)">
              <b-form-textarea
                v-model="scheduler_info.communication_description"
                :placeholder="lang('t_description')"
                rows="3"
                max-rows="6"
                trim
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :description="
                lang('t_youCanAddCommaBetweenEmailAddressesToaddMore')
              "
              :label="lang('t_to')"
            >
              <b-form-input
                v-model="scheduler_info.communication_to"
                :placeholder="lang(`t_to`)"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :description="
                lang('t_youCanAddCommaBetweenEmailAddressesToaddMore')
              "
              :label="lang('t_cc')"
            >
              <b-form-input
                v-model="scheduler_info.communication_cc"
                :placeholder="lang(`t_cc`)"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      size="lg"
      static
      id="modal-crons"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      centered
      ok-only
      :title="lang('t_examples')"
    >
      <b-table
        class="position-relative"
        :items="cron_examples"
        responsive
        :fields="cron_fields"
      >
        <template #cell(action)="data">
          <center>
            <b-button
              @click="set_cron_template(data.item.cron)"
              variant="success"
              >{{ lang("t_apply") }}
            </b-button>
          </center>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import cronParser from "cron-parser";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
globalThis.cronParser = cronParser;
export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
  },

  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      modal_record_details: false,
      cronstrue: "",
      perPage: 1,
      totalUsers: 0,
      perPageOptions: "",
      searchQuery: "",
      sortBy: "",
      isSortDirDesc: "",
      currentPage: 1,
      agents: [],
      projects: [],
      queues: [],
      ivr_list: [],
      report_category: "",
      categories: [
        { internal_name: "queue", display_name: globalThis._lang("t_queue") },
        { internal_name: "agent", display_name: globalThis._lang("t_agent") },
        {
          internal_name: "verdict",
          display_name: globalThis._lang("t_finishCode"),
        },
        {
          internal_name: "classification",
          display_name: globalThis._lang("t_classification"),
        },
        { internal_name: "dialer", display_name: globalThis._lang("t_dialer") },
        { internal_name: "sms", display_name: globalThis._lang("t_SMS") },
      ],
      cron_examples: [
        {
          cron: "* * * * *",
          description: globalThis._lang("t_cron1"),
          action: "",
        },
        {
          cron: "0 * * * *",
          description: globalThis._lang("t_cron2"),
          action: "",
        },
        {
          cron: "0 0 * * *",
          description: globalThis._lang("t_cron3"),
          action: "",
        },
        {
          cron: "0 17 * * *",
          description: globalThis._lang("t_cron4"),
          action: "",
        },
        {
          cron: "0 0 * * 0",
          description: globalThis._lang("t_cron5"),
          action: "",
        },
        {
          cron: "0 0 1 * *",
          description: globalThis._lang("t_cron6"),
          action: "",
        },
        {
          cron: "0 0 1 1 *",
          description: globalThis._lang("t_cron7"),
          action: "",
        },
        {
          cron: "0 0 * * 1-5",
          description: globalThis._lang("t_cron8"),
          action: "",
        },
        {
          cron: "0 0 15 * *",
          description: globalThis._lang("t_cron9"),
          action: "",
        },
        {
          cron: "0 0 1 * 1",
          description: globalThis._lang("t_cron10"),
          action: "",
        },
        {
          cron: "0 0 15 1,6,12 *",
          description: globalThis._lang("t_cron11"),
          action: "",
        },
        {
          cron: "0 0 1 * 1-5",
          description: globalThis._lang("t_cron12"),
          action: "",
        },
        {
          cron: "0 0 * * 6,0",
          description: globalThis._lang("t_cron13"),
          action: "",
        },
      ],
      cron_fields: [
        { key: "cron", label: globalThis._lang("t_cron"), sortable: true },
        {
          key: "description",
          label: globalThis._lang("t_description"),
          sortable: true,
        },
        { key: "action", label: globalThis._lang("t_action"), sortable: true },
      ],
      report_list: [],
      project_columns: [],
      report_permissions: [],
      column_labels: {
        status: "Durum",
        assigned_agent: "Agent",
        attempts: "Temas",
        total_attempts: "Toplam Temas",
        campaign: "Kampanya",
        finish_code: "Son Sonuç Kodu",
        insert_date: "Arama T.",
        action_date: "Aranacak T.",
        create_date: "Yüklenme T.",
        ex_agent: "Ex Agent",
        assign_date: "Atama T.",
      },
      scheduler_categories: [
        { internal_name: "report", display_name: globalThis._lang("t_report") },
      ],
      cron_info: {
        minute: "0",
        hour: "*",
        day: "*",
        month: "*",
        weekday: "*",
      },
      communication_types: [
        { internal_name: "mail", display_name: globalThis._lang("t_email") },
        {
          internal_name: "mobile_notification",
          display_name: globalThis._lang("t_mobileNotification"),
        },
      ],
      scheduler_info: {
        selected_scheduler_category: "report",
        display_name: "",
        cron: "* * * * *",
        readable_string: "",
        communication_type: "mail",
        communication_title: "",
        communication_description: "",
        communication_to: "",
        communication_cc: "",
        selected_report: "",
        filter_values: {
          lastXDays: 1,
          selectedAgents: [],
          selectedQueues: [],
          selectedProjects: [],
          selectedCampaigns: [],
          selectedCustomerColumns: [],
          selectedIvr: "",
          title: "",
          selectedNumber: "",
          isFileNotNull: false,
        },
      },
      isEdit: false,
      records: [],
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "cron",
          label: globalThis._lang("t_schedulerTime"),
          sortable: true,
        },
        {
          key: "readable_string",
          label: globalThis._lang("t_schedulerTimeString"),
          sortable: true,
        },
        {
          key: "next_run_time",
          label: globalThis._lang("t_nextRunTime"),
          sortable: true,
        },
        {
          key: "creator",
          label: globalThis._lang("t_creator"),
          sortable: true,
        },
        {
          key: "create_date",
          label: globalThis._lang("t_date"),
          sortable: true,
        },
        {
          key: "actions",
          label: globalThis._lang("t_actions"),
          sortable: false,
        },
      ],
    };
  },
  methods: {
    set_cron_template(cron_string) {
      console.log(cron_string);
      let cron_parts = cron_string.split(" ");
      this.cron_info["minute"] = cron_parts[0];
      this.cron_info["hour"] = cron_parts[1];
      this.cron_info["day"] = cron_parts[2];
      this.cron_info["month"] = cron_parts[3];
      this.cron_info["weekday"] = cron_parts[4];
      this.set_cron();
      this.$bvModal.hide("modal-crons");
    },
    isCronInvalid() {
      try {
        globalThis.cronParser.parseExpression(this.scheduler_info.cron);
        return true;
      } catch (error) {
        return false;
      }
    },
    updateSelected(item) {
      let cron_parts = item.cron.split(" ");
      this.cron_info["minute"] = cron_parts[0];
      this.cron_info["hour"] = cron_parts[1];
      this.cron_info["day"] = cron_parts[2];
      this.cron_info["month"] = cron_parts[3];
      this.cron_info["weekday"] = cron_parts[4];

      this.scheduler_info = {
        ...item,
        communication_to: item.communication_to.join(","),
        communication_cc: item.communication_cc.join(","),
      };
      this.isEdit = true;
      this.modal_record_details = true;
    },

    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/sch/v1/Scheduler `)).data;

      this.records = response;
      this.clear_data();
      this.is_progress = false;
    },
    set_cron() {
      this.scheduler_info.cron = `${this.cron_info.minute} ${this.cron_info.hour} ${this.cron_info.day} ${this.cron_info.month} ${this.cron_info.weekday}`;
      this.scheduler_info.readable_string = this.cronstrue.toString(
        this.scheduler_info.cron,
        {
          use24HourTimeFormat: true,
          locale: globalThis.selected_lang ?? "tr",
        }
      );
    },
    clear_report() {
      this.fields = [];
      this.items = [];
      this.scheduler_info.filter_values = {
        startDate: new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
        endDate: new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
        selectedAgents: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedCampaigns: [],
        selectedCustomerColumns: [],
        selectedIvr: "",
        title: "",
        selectedNumber: "",
        isFileNotNull: false,
      };

      this.scheduler_info.selected_report = "";
    },
    clear_data() {
      this.cron_info = {
        minute: "0",
        hour: "*",
        day: "*",
        month: "*",
        weekday: "*",
      };

      this.scheduler_info = {
        selected_scheduler_category: "report",
        display_name: "",
        cron: "* * * * *",
        readable_string: "",
        communication_type: "mail",
        communication_title: "",
        communication_description: "",
        communication_to: "",
        communication_cc: "",
        selected_report: {},
        filter_values: {
          lastXDays: 1,
          selectedAgents: [],
          selectedQueues: [],
          selectedProjects: [],
          selectedCampaigns: [],
          selectedCustomerColumns: [],
          selectedIvr: "",
          title: "",
          selectedNumber: "",
          isFileNotNull: false,
        },
      };

      this.set_cron();
    },
    confirmText(item) {
      this.scheduler_info = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteScheduler();
        }
      });
    },
    setScheduler: async function () {
      try {
        this.is_progress = true;
        let response;
        if (!this.isEdit) {
          response = (
            await this.$http_in.post(
              `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/sch/v1/Scheduler`,
              this.scheduler_info
            )
          ).data;
        } else {
          response = (
            await this.$http_in.put(
              `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/sch/v1/Scheduler`,
              this.scheduler_info
            )
          ).data;
        }

        if (response && response.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.get_records();
        this.is_progress = false;
      }
    },
    deleteScheduler: async function () {
      try {
        this.is_progress = true;
        let response = (
          await this.$http_in.delete(
            `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/sch/v1/Scheduler/${this.scheduler_info._id}`
          )
        ).data;

        if (response && response.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.get_records();
        this.is_progress = false;
      }
    },

    get_users: async function () {
      var response = (await this.$http_in.get(`report/v1/User`)).data;

      this.agents = response;
    },

    GetProjects: async function () {
      var response = (await this.$http_in.get(`report/v1/Project`)).data;

      this.projects = response;
    },

    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          // this.selected_queues.push(item.internal_name);
        } else {
          this.queues.filter(e => e.internal_name != item.internal_name)
        }
      }
    },
    GetCampaigns: async function () {
      var response = (await this.$http_in.get(`report/v1/Campaign`)).data;

      this.campaigns = response;
    },
    getCustomerColumns: async function () {
      // //console.log('this.agent.Project.INTERNAL_NAME', this.agent);
      var response = (await this.$http_in.get(`crm/v1/CustomerColumns`)).data;
      this.project_columns = [];
      this.columns = response[0].allkeys.sort();
      for (const item of response[0].allkeys) {
        if (
          [
            "active",
            "_id",
            "notes",
            "phones",
            "products",
            "extra_data",
            "sms",
            "attempts",
            "campaign",
          ].includes(item)
        ) {
          continue;
        }
        this.project_columns.push({
          text:
            this.column_labels[item] == undefined
              ? item
              : this.column_labels[item],
          value: item,
        });
      }
    },
    GetIVR: async function () {
      var response = (await this.$http_in.get(`report/v1/Ivr`)).data;

      this.ivr_list = response;
    },
    GetReports: async function () {
      var response = (await this.$http_in.get(`report/v1/Reports`)).data;

      this.report_list = [];
      for (const item of response) {
        item.display_name = globalThis._lang(item.display_name);
        if (this.report_permissions.includes(item.internal_name)) {
          for (const field of item.fields) {
            field.label = globalThis._lang(field.label);
          }
          this.report_list.push(item);
        }
      }
      console.log("test", response);
    },
  },

  mounted: async function () {
    this.is_progress = true;
    this.report_permissions = globalThis.permissions["report"] || [];
    this.cronstrue = globalThis.cronstrue;
    this.get_records();
    this.getCustomerColumns();
    this.GetReports();
    this.get_users();
    this.GetProjects();
    this.GetQueues();
    this.GetCampaigns();
    this.GetIVR();
    this.set_cron();

    this.is_progress = false;
    // this.GetInboundRouteTargets();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
